import axios from "axios";
import React, { useEffect, useState } from "react";
import appointmentService from "../../services/appointmentServices";
import { linkUrl } from "../../utilies/linkUrl";
import MessageBox from "../../utilies/MessageBox";

const AppointmentComponent = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [appoDate, setAppoDate] = useState("");
  const [phone, setphone] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [succmesg, setSuccmesg] = useState(null);
  const [appoDetail, setAppoDetail] = useState();
  useEffect(() => {
    const appointmentData = async () => {
      setLoading(true);
      const service = await appointmentService();
      if (service) {
        setAppoDetail(service);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    appointmentData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const submitValue = async (e) => {
    e.preventDefault();
    setLoading(true);
    const appointmentForm = {
      fullName,
      email,
      appoDate,
      phone,
    };

    if (appointmentForm !== null) {
      try {
        const res = await axios.post(`${linkUrl}/appointment`, appointmentForm);
        setFullName("");
        setEmail("");
        setAppoDate("");
        setphone("");

        if (res.data.status === 200) {
          setLoading(false);
          setSuccmesg("We Receive Your Appointment Request");
          //  setOffers(res.data.data[0]);
          setError(null);
        }
      } catch (err) {
        setError("Something went worng or Appointment date not found");
        setLoading(false);
        // setOffers(null);
      } finally {
        setLoading(false);
      }
    }

    setFullName("");
    setEmail("");
    setAppoDate("");
    setphone("");
  };

  return (
    <>
      <div className="" style={{ backgroundColor: "#57c8e1" }}>
        <div className="container">
          <div className="row align-items-center pt-1">
            <div className="col-md-5 wow fadeInLeft  animated" data-animation="fadeInLeft"
              data-delay=".4s" >
              <div className="section-title mb-50">
                <h5>Appointment</h5>
                {appoDetail && appoDetail.appointment_title && (
                  <h2 className="text-white">
                    {appoDetail.appointment_title
                      ? appoDetail.appointment_title
                      : ""}
                  </h2>
                )}
              </div>
              {appoDetail && appoDetail.appointment_des && (
                <p
                  className="text-white mb-5" id="appo_text"
                  dangerouslySetInnerHTML={{
                    __html: appoDetail.appointment_des,
                  }}
                ></p>
              )}
            </div>
            <div className="col-md-7 wow fadeInRight  animated" data-animation="fadeInRight"
              data-delay=".4s">
              <div className="contact-bg02 p-5">
                <div className="section-title center-align">
                  <h2>Make Appointment</h2>
                </div>
                {error ? <MessageBox varient="danger">{error}</MessageBox> : ""}

                <>
                  {succmesg ? (
                    <MessageBox varient="success">{succmesg}</MessageBox>
                  ) : (
                    ""
                  )}
                </>
                <form onSubmit={submitValue} className="contact-form mt-30">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact-field p-relative c-name mb-20">
                        <input
                          type="text"
                          id="firstn"
                          name="fullName"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Eamil"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="date"
                          id="startDate"
                          name="appoDate"
                          min={disablePastDate()}
                          value={appoDate}
                          placeholder="Date"
                          onChange={(e) => setAppoDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contact-field p-relative c-subject mb-20">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          value={phone}
                          onChange={(e) => setphone(e.target.value)}
                          placeholder="Phone No."
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="slider-btn">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                          disabled={loading ? true : false}
                        >
                          <span>Submit Now</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentComponent;
