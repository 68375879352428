import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";


const sliderService = async () => {
    const res = await axios.get(`${linkUrl}/slider`);
    if (res.data.status === 200) {
        return res.data.data;
    }
}

export default sliderService;