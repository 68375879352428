import axios from "axios";
import React, { useEffect, useState } from "react";
import BannerComponent from "../Components/Common/BannerComponent";
import BookAppointmentComponent from "../Components/Common/BookAppointmentComponent";
import { imgUrl, linkUrl } from "../utilies/linkUrl";

const TeamPage = () => {
  const [teams, setTeams] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const TeamsData = async () => {
      setIsLoading(true);
      const teams_data = await axios.get(`${linkUrl}/alldoctors`);
      if (teams_data.data.status === 200) {
        setTeams(teams_data.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    TeamsData();
  }, []);
  return (
    <>

{isLoading ? (
        <div className="text-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
              <BannerComponent title="Our Team" subTitle="Team" />
      <section className="team-area fix p-relative pt-120 pb-90">
        <div className="container">
          <div className="row">
            {teams &&
              teams.length > 0 &&
              teams.map((t, idx) => (
                <div className="col-lg-3 col-md-4 mb-4 col-sm-6 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s" key={idx}>
                  <div className="single-team  h-100" style={{ backgroundColor:"#f0f8ff" }}>
                    <div className="team-thumb">
                      <div className="brd">
                        {t.doc_img ? (
                          <img
                            className="img-fluid"
                            src={`${imgUrl}${t.doc_img}`}
                            alt=""
                          />
                        ) : (
                          <img src="assets/img/team/team01.png" alt="img" />
                        )}
                      </div>{" "}
                    </div>
                    <div className="team-info">
                      <h4>
                      {t.doc_name?t.doc_name:""}
                      </h4>
                     <p><b>{t.position?t.position:""}</b></p> 
                     <p><b>{t.department?t.department:""}</b></p> 
                     {
                                t.nmc &&
                            <p className="text-primary"><b>{`${t.nmc?t.nmc:""}`}</b></p>

                            }
                
                    </div>
                  </div>
                </div>
              ))}


          </div>
        </div>
      </section>

      <BookAppointmentComponent />
        </>
      )
      }

    </>
  );
};

export default TeamPage;
