import React, { useEffect, useState } from "react";
import servicDataService from "../../services/ourServices";
import { imgUrl } from "../../utilies/linkUrl";

const ServiceHomeComponent = ({services}) => {
  return (
    <section
      id="service-details2"
      className="service-details-two pt-120 pb-105 p-relative"
    >

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title center-align mb-50 text-center">
              <h5>Our Services</h5>
              <h2>What We Provide</h2>
            </div>


          </div>
          {
              services &&
                services.length > 0 &&
                services.slice(0, 8).map((service, idx) => (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-15 wow fadeInDown animated mb-4"
                  data-animation="fadeInDown"
                  data-delay=".4s" key={idx}>
                  <div className="card h-100">
                    <img
                      className="card-img-top"
                      src={`${imgUrl}${service.service_img}`} alt="dental service"
                      height="180"
                    />
                    <div className="card-body">
                  
                      <h4 className="card-title text-center"> {service.service_name?service.service_name:""}</h4>
                     
                    </div>
                  </div>
                </div>

                ))
          }
        </div>
      </div>
    </section>
  );
};

export default ServiceHomeComponent;
