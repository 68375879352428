
import React from 'react'
import Slider from "react-slick";


const SliderHomeComponent = ({data}) => {

   // Check if myArray is null or empty
   const isEmpty = !data.sliders || !Array.isArray(data.sliders) || data.sliders.length === 0;
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <section id="home" className="slider-area slider-four fix p-relative">
    {
              isEmpty?(
                <></>
              ):(
                <Slider {...settings}>
                {
                   data && data.sliders.length > 0 && data.imgurl &&
                   data.sliders.slice(0,5).map((s,idx)=>(
                     <div className="slider-active" style={{Background: "#00173c"}} key={idx}>
                     <div>
                     <img src={`${data.imgurl}/${s.slider_img}`} className='img-fluid custom-slide' width="100%" height="50%" alt='' />
             
                     </div>
                  
                     </div>
       
                   ))
      
                }
                
              
                 
              
             
      
              
              </Slider>   


              )
    }
  
            

        
   
</section>
  )
}

export default SliderHomeComponent