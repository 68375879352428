import React from 'react'
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import AppointmentComponent from './Components/Common/AppointmentComponent';
import FooterComponent from './Components/Common/FooterComponent';
import NavBarComponent from './Components/Common/NavBarComponent';
import TopHeaderComponent from './Components/Common/TopHeaderComponent';
import AboutUsComponent from './Pages/AboutUsComponent';
import ContactPage from './Pages/ContactPage';
import  HomePage  from './Pages/HomePage';
import ServicesComponent from './Pages/ServicesComponent';
import TeamPage from './Pages/TeamPage';

const MyRoute = () => {
  return (
    <>
        <Router>
        <TopHeaderComponent/>
        <NavBarComponent/>
            <Routes>

            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUsComponent />} />
            <Route path="/services" element={<ServicesComponent />} />
            <Route path="/teams" element={<TeamPage />} />
            <Route path="/appointment" element={<AppointmentComponent />} />

            <Route path="/contact-us" element={<ContactPage />} />



            </Routes>
            <FooterComponent/>
        </Router>
    </>
  )
}

export default MyRoute