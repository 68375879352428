import React, { useEffect, useState } from "react";
import AppointmentComponent from "../Components/Common/AppointmentComponent";
import AboutUsHomeComponent from "../Components/HomeComponent/AboutUsHomeComponent";
import ServiceHomeComponent from "../Components/HomeComponent/ServiceHomeComponent";
import SliderHomeComponent from "../Components/HomeComponent/SliderHomeComponent";
import aboutService from "../services/aboutServices";
import appointmentService from "../services/appointmentServices";
import servicDataService from "../services/ourServices";
import sliderService from "../services/sliderServices";

const HomePage = () => {
  const [aboutData, setAboutData] = useState({});
  const [sliderData, setSliderData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([
      aboutService(),
      sliderService(),
      servicDataService(),
    ])
      .then(([aboutRes, sliderRes, servicesRes]) => {
        setAboutData(aboutRes);
        setSliderData(sliderRes);
        setServicesData(servicesRes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <SliderHomeComponent data={sliderData} />
          <AboutUsHomeComponent about={aboutData} />
          <AppointmentComponent />

          <ServiceHomeComponent services={servicesData} />
        </>
      )}
    </>
  );
};

export default HomePage;
