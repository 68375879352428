import FooterComponent from "./Components/Common/FooterComponent";
import NavBarComponent from "./Components/Common/NavBarComponent";
import TopHeaderComponent from "./Components/Common/TopHeaderComponent";
import MyRoute from "./MyRoute";


function App() {
  return (
    <div >
  <MyRoute/>
    </div>
  );
}

export default App;
