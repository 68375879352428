import React, { useEffect, useState } from "react";
import siteSetting from "../../services/settingServices";

const TopHeaderComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);
  return (
    <div className="header-top second-header d-none d-md-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-8 d-none d-lg-block">
            <div className="header-cta">
              <ul>
                {data && data.site_email && (
                  <li>
                    <i className="icon fal fa-envelope"></i>
                    <span>{data.site_email ? data.site_email : ""}</span>
                  </li>
                )}
                {data && data.site_phone && (
                  <li>
                    <i className="icon fal fa-phone"></i>
                    <span>{data.site_phone ? data.site_phone : ""}</span>
                  </li>
                )}

                {data && data.site_mobile && (
                  <li>
                    <i className="icon fal fa-mobile"></i>
                    <span>{data.site_mobile ? data.site_mobile : ""}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 d-none d-lg-block text-right">
            <div className="header-social">
              <span>
                {data && data.site_fb_link && (
                  <a
                    href={`${data.site_fb_link ? data.site_fb_link : ""}`}
                    target="_blank"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                )}
                {data && data.site_lin_link && (
                  <a
                    href={`${data.site_lin_link ? data.site_lin_link : ""}`}
                    target="_blank"
                    title="LinkedIn"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                )}

                {data && data.site_tw_link && (
                  <a
                    href={`${data.site_tw_link ? data.site_tw_link : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}

                {data && data.site_in_link && (
                  <a
                    href={`${data.site_in_link ? data.site_in_link : ""}`}
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderComponent;
